import React, { useState, useEffect } from "react"
import "table.css"

function TerraClassicEscrows() {
  const [escrowIds, setEscrowIds] = useState([]);
  const [escrowDetails, setEscrowDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the first URL to get escrow IDs
    fetch("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1xk4t54tv4xr505a37qjgdx2jlfrgxwkvuz9e7q0m52z3h2u0ysfsv05fcr/smart/eyJsaXN0Ijp7fX0=")
      .then((response) => response.json())
      .then((data) => {
        setEscrowIds(data.data.escrows);
      })
      .catch((error) => {
        console.error("Error fetching escrow IDs:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch escrow details for each ID and store them in escrowDetails array
    const fetchEscrowDetails = async () => {
      setIsLoading(true); // Set loading to true while fetching data
      const detailsPromises = escrowIds.map(async (id) => {
        const encodedId = btoa(JSON.stringify({ details: { id } }));
        const url = `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1xk4t54tv4xr505a37qjgdx2jlfrgxwkvuz9e7q0m52z3h2u0ysfsv05fcr/smart/${encodedId}`;
        
        try {
          const response = await fetch(url);
          const data = await response.json();
          return data.data;
        } catch (error) {
          console.error(`Error fetching escrow details for ID ${id}:`, error);
          return null;
        }
      });
  
      const details = await Promise.all(detailsPromises);
      setEscrowDetails(details);
      setIsLoading(false); // Set loading to false after data is fetched
    };
  
    if (escrowIds.length > 0) {
      fetchEscrowDetails();
    }
  }, [escrowIds]);

  function unixTimestampToDDMMMYY(unixTimestamp) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
    const dd = String(date.getDate()).padStart(2, '0'); // Day
    const mmm = months[date.getMonth()]; // Three-letter month abbreviation
    const yy = String(date.getFullYear()).slice(-2); // Last two digits of the year
  
    return `${dd} ${mmm} ${yy}`;
  }

  function addCommasToNumber(number) {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  function calculateCompletionPercentage(startTime, endTime) {
    const currentDate = new Date();
    const startDate = new Date(startTime * 1000); // Convert to milliseconds
    const endDate = new Date(endTime * 1000);
    const totalDuration = endDate - startDate;
    const elapsedDuration = currentDate - startDate;

    if (currentDate >= endDate) {
      return 100; // Return 100% if the current date is past the end date
    } else {
      return Math.min(Math.round((elapsedDuration / totalDuration) * 100), 100);
    }
  }

  // Render the table using escrowDetails data
  return (
    <div>
      <h1 className="padded-header" >GRDX Staking List</h1>
      <div className="table-container">

      {isLoading ? (
          // Display loading message when data is being fetched
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
            <p style={{ fontSize: '2em', fontWeight: 'bold' }}>Loading...</p>
          </div>
        ) : (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Recipient</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Staked Amount</th>
                <th>Current Balance</th>
                <th>End Balance</th>  
                <th>APY</th>
                <th>Complete</th>
              </tr>
            </thead>
            <tbody>
            {escrowDetails.filter(details => parseInt(details.id, 10) >= 1000).map((details, index) => (
                <tr key={index}>
                  <td>{details.id}</td>
                  <td>{details.recipient.slice(0, 7) + "..." + details.recipient.slice(-4)}</td>
                  <td>{unixTimestampToDDMMMYY(details.start_time)}</td>
                  <td>{unixTimestampToDDMMMYY(details.end_time)}</td>
                  <td>{addCommasToNumber(details.principal/1000000)}</td>
                  <td>{addCommasToNumber(details.current_balance/1000000)}</td>
                  <td>{addCommasToNumber(details.mature_balance/1000000)}</td>
                  <td>{details.apr + "%"}</td>
                  <td>{calculateCompletionPercentage(details.start_time, details.end_time) + "%"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        )}
      </div>
    </div>
  );
}

export default TerraClassicEscrows;
