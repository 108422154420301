import { Int, MsgExecuteContract } from "@terra-money/terra.js"
import { useAddress } from "hooks"
import { div, floor, times } from "libs/math"
import { toAmount } from "libs/parse"
import { Type } from "pages/Swap"
import { useEffect, useMemo, useState } from "react"
import { tokenInfos } from "./usePairs"


type Params = {
  from: string
  to: string
  amount: number | string
  type?: Type
  staking_days: number
  description: string
}

const useTBC = (params: Params) => {
  const walletAddress = useAddress()
  const {
    from,
    to,
    type,
    amount: _amount,
    staking_days, 
    description,
  } = params

  const amount = Number(_amount)
  const debouncedAmount = useDebounce(amount, 500)
  const [isLoading, setIsLoading] = useState(false)
  const [msgs, setMsgs] = useState<
    (MsgExecuteContract[] | MsgExecuteContract)[]
  >([])
  const [autoRefreshTicker, setAutoRefreshTicker] = useState(false)
  const [simulatedAmounts, setSimulatedAmounts] = useState<number[]>([])

  // From api to smart contract
  let [currentPrice, setCurrentPrice] = useState(0)
  let [luna1Price, setLuna1Price] = useState(0)

  let currentMarketCap = 0
  const tokenInfo = tokenInfos.get(to)

  // Will calculate new values
  let tokensToStake = 0
  let reserveReturned = 0
  let newMarketCap = 0

  const profitableQuery = useMemo(() => {
    let luncUsdPrice = 0
    let luncLbunPrice = 0
    let coinDeposit = 0
    let msg = undefined

    let simulatedAmount = 0
    let rate = 0

    console.log("useMemo triggered: ", staking_days, description);

    setIsLoading(true)
    if (!debouncedAmount) {
      return undefined
    } else if (from === "terra12f3f5fzfzxckc0qlv3rmwwkjfhzevpwmx77345n0zuu2678vxf0sm6vvcw") {
      
      
      //Amount of LUNC after the seller fee and min transaction fee is deducted
      const buyAmount = (debouncedAmount * 1000000)

      //Same cuz not swapping
      tokensToStake = buyAmount

      simulatedAmount = tokensToStake
      simulatedAmount = simulatedAmount < 0 ? 0 : simulatedAmount

      rate = (buyAmount/1000000) / tokensToStake
      rate = rate < 0 ? 0 : rate

      const executeMsg = {
        send:{
          amount: simulatedAmount.toString(), 
          contract: "terra1jx8ppu25ll66z96vl5s93kdlq6yavmrltn4rzume6lklsvddrvqslasn03", 
          msg: btoa(JSON.stringify({
            staking_days: staking_days,
            description: description,
          })),
        },
      };

      msg = new MsgExecuteContract(
        walletAddress,
        "terra12f3f5fzfzxckc0qlv3rmwwkjfhzevpwmx77345n0zuu2678vxf0sm6vvcw",
        executeMsg,
        //{ uluna: (debouncedAmount * 1000000).toString() }
      )
    } else {
      return undefined
    }


    luncUsdPrice = 0
    luncLbunPrice = 0

    const tokenRoutes: string[] = []
    setIsLoading(false)
    return {
      msg,
      luncUsdPrice,
      luncLbunPrice: luncLbunPrice.toString(),
      simulatedAmount,
      tokenRoutes,
      price: rate.toString(),
      //currentSupply,
      //currentReserve,
      //currentPrice
    }
  }, [to, debouncedAmount, msgs, simulatedAmounts, staking_days, description]) //end of useMemo


  useEffect(() => {
    const timerId = setInterval(() => {
      setAutoRefreshTicker((current) => !current)
    }, 30000)
    return () => {
      clearInterval(timerId)
    }
  }, [debouncedAmount, from])

  const result = useMemo(() => {
    if (!from || !to || !type || !debouncedAmount) {
      return { profitableQuery: undefined, isLoading: false }
    }
    return {
      isLoading,
      profitableQuery,
    }
  }, [debouncedAmount, from, isLoading, profitableQuery, to, type])

  return result
}

// Generic deBounce Hook ( https://usehooks.com/useDebounce/ )
function useDebounce(value: number, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export default useTBC
